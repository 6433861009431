import { niveaux } from "./prixBaguettes"

export const get_types_produitAnnexe = products => {
  let typesPassePartout = []
  products.map(({ node }) => {
    if (!typesPassePartout.includes(node.pat_id)) {
      typesPassePartout.push(node.pat_id)
    }
  })
  return typesPassePartout
}

export const select_categories = products => {
  let categories = []
  let categoriesExist = []
  products.map(node => {
    if (!categoriesExist.includes(node.cat_libelle)) {
      categoriesExist.push(node.cat_libelle)
      categories.push({
        node: {
          cat_libelle: node.cat_libelle,
          cat_id: node.cat_id,
        },
      })
    }
  })

  const order = niveaux.reduce((acc, cur) => [...acc, ...cur.categs], [])
  return categories.filter(e => order.includes(e.node.cat_id)).sort((a, b) => order.indexOf(a.node.cat_id) - order.indexOf(b.node.cat_id))
}

export const select_couleur = (allproducts, products) => {
  let couleursExist = []
  let couleurs = []
  products.map(node => {
    if (!couleursExist.includes(node.cog_id)) {
      couleursExist.push(node.cog_id)

      let ba_forid = allproducts.find(u => parseInt(u.ba_ref_interne) === parseInt(node.cog_ba_ref))

      couleurs.push({
        node: {
          co_id: node.co_id,
          co_libelle: node.co_libelle,
          co_fk_cog: node.co_fk_cog,
          cog_id: node.cog_id,
          cog_libelle: node.cog_libelle,
          cog_hexa: node.cog_hexa,
          cog_order: node.cog_order,
          cog_image_ba_id: node.cog_ba_ref === null || typeof ba_forid === "undefined" ? null : ba_forid.ba_id,
        },
      })
    }
  })

  couleurs.sort((a, b) => a.node.cog_order - b.node.cog_order)
  return couleurs
}
