import React from "react"
import PropTypes from "prop-types"
import { navigate } from "@reach/router"
import { Link } from "gatsby-plugin-spinner"
import { StaticQuery, graphql } from "gatsby"
import { connect } from "react-redux"
import { get_types_produitAnnexe } from "../services/selectNiveaux"

import Img from "gatsby-image"
import SEO from "./../components/seo"
import Layout from "../components/layout"

class AccessoiresPage extends React.Component {
  constructor(props) {
    super(props)
    const slugify = require("slugify")
    this.hauteurInput = React.createRef()
    this.GoToTheSelector = this.GoToTheSelector.bind(this)
    this.state = {
      typesPassePartout: get_types_produitAnnexe(this.props.mesAccessoires.allMysqlAccessoires.edges),
      slugify: slugify,
    }
  }

  GoToTheFuture = event => {
    if (typeof window !== "undefined" && event.target.value !== "0") {
      if (this.props.mesAccessoires.allMysqlAccessoires.edges.filter(u => u.node.pa_id == event.target.value).length > 0) {
        //console.log(this.props.mesAccessoires.allMysqlAccessoires.edges.filter(u => u.node.pa_id == event.target.value)[0].node.pa_libelle)
        const libelle = this.props.mesAccessoires.allMysqlAccessoires.edges.filter(u => u.node.pa_id == event.target.value)[0].node.pa_libelle
        navigate(`/accessoires/${this.state.slugify(libelle)}-${event.target.value}`)
      }
    }
  }

  GoToTheSelector() {
    this.hauteurInput?.current?.focus()
  }

  render() {
    return (
      <>
        <Layout banner={false} location={this.props.location}>
          <SEO
            title="Accessoires pour cadres"
            description="Pochettes de protection, tournettes de fixation chassis, crochets de suspension, rouleaux de kraft, fibranne, adhésifs, ..."
          />

            <div className="columns" style={{ marginTop: "4.6rem", marginBottom: "0.5rem" }}>
              <div className="column is-9 family pt-6">
                {this.state.typesPassePartout
                  .filter(u => u === 26)
                  .map((id_type, index1) => (
                    <section key={index1} id={`menu-${id_type}`} onClick={this.GoToTheSelector} onKeyPress={() => {}} role="button" tabIndex={0}>
                      <h2>{this.props.mesAccessoires.allMysqlAccessoires.edges.filter(u => u.node.pat_id === id_type)[0].node.pat_libelle}</h2>
                      <div className="family-grid">
                        <div>
                          <h4 className="SelectAcces" style={{ color: "#a2388d" }}>
                            {this.props.mesAccessoires.allMysqlAccessoires.edges.filter(u => u.node.pat_id === id_type)[0].node.pa_libelle}
                          </h4>

                          <div>
                            <div className="cadreFamille hoverSelect">
                              <Img
                                fluid={
                                  this.props.mesAccessoires.allMysqlAccessoires.edges.filter(u => u.node.pat_id === id_type)[0].node.mysqlImages[0]
                                    .childImageSharp.fluid
                                }
                              />
                            </div>
                          </div>
                          <div className="field">
                            <div className="control">
                              <select
                                className="select is-primary is-rounded SelectAcces"
                                onChange={this.GoToTheFuture}
                                onBlur={this.GoToTheFuture}
                                ref={this.hauteurInput}
                              >
                                <option value="0">Sélectionnez une dimension de pochette</option>
                                {this.props.mesAccessoires.allMysqlAccessoires.edges.map(({ node }, index) =>
                                  node.pat_id === id_type ? (
                                    <option key={index} value={node.pa_id}>
                                      Dimensions : {node.largeur} X {node.hauteur}
                                    </option>
                                  ) : (
                                    ""
                                  )
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  ))}
                {this.state.typesPassePartout
                  .filter(u => u !== 26)
                  .map((id_type, index1) => (
                    <section key={index1} id={`menu-${id_type}`}>
                      <h2>{this.props.mesAccessoires.allMysqlAccessoires.edges.filter(u => u.node.pat_id === id_type)[0].node.pat_libelle}</h2>
                      <div className="family-grid">
                        {this.props.mesAccessoires.allMysqlAccessoires.edges.map(({ node }, index) =>
                          node.pat_id === id_type ? (
                            <div key={index}>
                              <Link to={`/accessoires/${this.state.slugify(node.pa_libelle)}-${node.pa_id}`}>
                                <div>
                                  <div>
                                    {/*node.pa_id*/}
                                    <h4 className="SelectAcces">{node.pa_libelle}</h4>
                                  </div>
                                  <div className="cadreFamille">
                                    <Img fluid={node.mysqlImages[0].childImageSharp.fluid} />
                                  </div>
                                </div>
                              </Link>
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </div>
                    </section>
                  ))}
              </div>
              <div className="column is-3 is-fullheight has-background-grey-lighter">
                <ul className="menuCategory" style={{top: '6rem'}}>
                  {this.state.typesPassePartout.map((id_type, index1) => 
                    <li key={index1}>
                          <a
                            href={`cadres/accessoires/#menu-${id_type}`}
                            className={`navbar-item`}
                            onClick={evt => {
                              evt.preventDefault()
                              window.scrollTo({
                                top: window.document.querySelector(`#menu-${id_type}`).offsetTop,
                                left: 0,
                                behavior: "smooth",
                              })
                            }}
                          >
                            {this.props.mesAccessoires.allMysqlAccessoires.edges.filter(u => u.node.pat_id === id_type)[0].node.pat_libelle}
                          </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
        </Layout>
      </>
    )
  }
}

AccessoiresPage.propTypes = {
  identifiants: PropTypes.object,
  mesAccessoires: PropTypes.object,
  location: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    identifiants: state.identifiants,
  }
}

const ConnectFunction = connect(mapStateToProps)(AccessoiresPage)

const StaticAccessoiresPage = props => (
  <StaticQuery
    query={graphql`
      query accessoires {
        allMysqlAccessoires {
          edges {
            node {
              pa_id
              pat_libelle
              pat_id
              pa_libelle
              pa_description
              pa_reference
              largeur
              hauteur

              mysqlImages {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={mesAccessoires => <ConnectFunction mesAccessoires={mesAccessoires} {...props} />}
  />
)

export default StaticAccessoiresPage
